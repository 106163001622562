import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  AddBlackListSuccess,
  DeleteSuccess,
  Loading,
  PostContentMinLength,
  ReportSuccess,
  Success,
  FailByErrorMessage,
  SuccessWithLargeMessage,
  LikeToast,
  PcLikeToast,
  IsLiked,
  MobileShowTypeToast,
  Fail,
} from 'components/toast';

import './global-toast.less';

// eslint-disable-next-line complexity
const GlobalToast = (props) => {
  const toastRef = useRef(null);
  const prevFocusElm = useRef(null);
  useEffect(() => {
    if (
      props.type
            && toastRef.current
            && typeof toastRef.current.focus === 'function'
    ) {
      // 保存出发toast的按钮
      if (prevFocusElm.current === null) {
        prevFocusElm.current = document.activeElement;
      }
      toastRef.current.focus();
    }

    if (
      !props.type
            && prevFocusElm.current
            && typeof prevFocusElm.current.focus === 'function'
    ) {
      prevFocusElm.current.focus();
      prevFocusElm.current = null;
    }
  }, [props.type]);

  if (!props.type) {
    return null;
  }

  return createPortal(
    (
      <div
        className={classNames('global-toast', { 'global-toast-align-start': isAlignItemsStart(props.type) })}
        ref={toastRef}
        role="dialog"
        aria-modal={true}
        aria-labelledby="global-toast__content"
        tabIndex={-1}
      >

        <div className="content" id="global-toast__content">

          {props.type === 'ontop' && <Success text="置顶成功" />}

          {props.type === 'oncomments' && <Success text="评论成功" />}

          {props.type === 'onsubmitting' && <Success text="回复成功" />}

          {props.type === 'offtop' && <Success text="取消置顶成功" />}

          {props.type === 'onhide' && <Success text="隐藏成功" />}

          {props.type === 'onhidereply' && <SuccessWithLargeMessage text="隐藏成功\n仅发布人可见，可在管理后台查看" />}

          {props.type === 'offhide' && <Success text="取消隐藏成功" />}

          {props.type === 'onspam' && <AddBlackListSuccess />}

          {props.type === 'onblock' && <Success text="加入黑名单成功" />}

          {props.type === 'offblock' && <Success text="取消黑名单成功" />}

          {props.type === 'offspam' && <Success text="取消黑名单成功" />}

          {props.type === 'onlock' && <Success text="禁止回复成功" />}

          {props.type === 'offlock' && <Success text="允许回复成功" />}

          {props.type === 'delete' && <DeleteSuccess />}

          {props.type === 'report' && <ReportSuccess />}

          {props.type === 'submitting' && <Loading text="回复发送中..." />}

          {props.type === 'commentsing' && <Loading text="评论发送中..." />}

          {props.type === 'minLimit' && <PostContentMinLength text={props.postContent} />}

          {props.type === 'ondelete' && <Success text="删除成功" />}

          {props.type === 'tippedSuccess' && <Success text="打赏成功" />}

          {props.type instanceof Error && <FailByErrorMessage text={props.type.message} />}

          {props.type === 'likedSuccess' && <LikeToast likePeople={props.likePeople} likePeopleCount={props.likePeopleCount} />}

          {props.type === 'pcLikedSuccess' && <PcLikeToast likePeople={props.likePeople} likePeopleCount={props.likePeopleCount} />}

          {props.type === 'mobileShowType' && <MobileShowTypeToast />}

          {props.type === 'isLiked' && <IsLiked />}

          {props.type === 'BannerUploadErr' && <Fail text={props.text} />}

          {props.type === 'customError' && <FailByErrorMessage text={props.errorText} />}

          {props.type === 'postSuccess' && <Success text="发帖成功" />}

          {props.type === 'loading' && <Loading text={props.text} />}

          {props.type === 'success' && <Success text={props.text} />}

          {props.type === 'fail' && <Fail text={props.text} />}

          {props.type === '个数已超出限制' && <Fail text="个数已超出限制" />}

        </div>

      </div>
    ), document.body,
  );
};



function isAlignItemsStart(type) {
  // 下面的提示类型，要顶部对齐
  return ['report'].indexOf(type) !== -1;
}
GlobalToast.propTypes = {
  postContent: PropTypes.string,
  likePeopleCount: PropTypes.string,
  likePeople: PropTypes.string,
  errorText: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
};

export { GlobalToast };
