import React from 'react';
import Icon from 'components/icon';
import Tag from 'components/tag';
import { TcIconReward, TcIconTick, TcIconShut, TcIconInfo, TcIconDelete } from 'components/IconSystem';

import './style.less';

export const Loading = ({ text = '页面加载中' }) => (
    <Tag.div className="toast">
        <Tag.div className="toast-icon-load">
            <Tag.div className="line-box">
                <Tag.span className="line-out line-out-A">
                    <Tag.i className="line-inner line-inner-A"></Tag.i>
                </Tag.span>
                <Tag.span className="line-out line-out-B">
                    <Tag.i className="line-inner line-inner-B"></Tag.i>
                </Tag.span>
                <Tag.span className="line-out line-out-C">
                    <Tag.i className="line-inner line-inner-C"></Tag.i>
                </Tag.span>
            </Tag.div>
        </Tag.div>
        <Tag.div className="text">{text}</Tag.div>
    </Tag.div>
);

export const Award = () => (
    <Tag.div className="toast">
        <Tag.div className="toast-icon">
            <TcIconReward className="tcIcon tcIcon_reward" />
        </Tag.div>
        <Tag.div className="text">打赏成功</Tag.div>
    </Tag.div>
);

export const Liked = () => (
    <Tag.div className="toast">
        <Tag.div className="toast-icon">
            <Icon className="tcIcon_liked" type="liked-dark" />
        </Tag.div>
        <Tag.div className="text">点赞成功</Tag.div>
    </Tag.div>
);

export const UnLike = () => (
    <Tag.div className="toast">

        <Tag.div className="toast-icon">
            <Icon className="tcIcon_likes" type="likes-light" />
        </Tag.div>

        <Tag.div className="text">取消点赞</Tag.div>

    </Tag.div>
);

export const Success = ({ text = '回复成功' }) => (
    <Tag.div className="toast">

        <Tag.div className="toast-icon">
            <Icon className="tcIcon_tick" type="tick-success" />
        </Tag.div>

        <Tag.div className="text">{text}</Tag.div>

    </Tag.div>
);

export const Fail = ({ text = '回复失败' }) => (
    <Tag.div className="toast">

        <Tag.div className="toast-icon">
            <TcIconShut className="tcIcon tcIcon_shut" />
        </Tag.div>

        <Tag.div className="text">{text}</Tag.div>

    </Tag.div>
);

export const FailByErrorMessage = ({ text = '未知错误\n请稍后重试' }) => (
    <Tag.div className="toast toast-large-message">

        <Tag.div className="toast-icon">
            <TcIconShut className="tcIcon tcIcon_shut" />
        </Tag.div>

        <Tag.div className="text">
            {text.split(/\\n|\n|<br\/>|<br \/>/).map((p, i) => <p key={i}>{p}</p>)}
        </Tag.div>

    </Tag.div>
);

export const Tips = ({ text = '提示信息' }) => (
    <Tag.div className="toast">

        <Tag.div className="toast-icon">
            <TcIconInfo />
        </Tag.div>

        <Tag.div className="text">{text}</Tag.div>

    </Tag.div>
);

export const DeleteSuccess = () => (
    <Tag.div className="toast">

        <Tag.div className="toast-icon">
            <TcIconDelete />
        </Tag.div>

        <Tag.div className="text">删除成功</Tag.div>

    </Tag.div>
);

export const AddBlackListSuccess = () => (
    <Tag.div className="toast toast-large-message">

        <Tag.div className="toast-icon">
            <TcIconTick />
        </Tag.div>

        <Tag.div className="text">

            <Tag.p>成功加入黑名单</Tag.p>
            <Tag.p>该用户所有帖子不再显示</Tag.p>

        </Tag.div>

    </Tag.div>
);

export const SuccessWithLargeMessage = ({ text }) => (
    <Tag.div className="toast toast-large-message">

        <Tag.div className="toast-icon">
            <TcIconTick />
        </Tag.div>

        <Tag.div className="text">

            {text.split(/\\n|\n|<br\/>|<br \/>/).map((p, i) => <Tag.p key={i}>{p}</Tag.p>)}

        </Tag.div>

    </Tag.div>
);

export const PostContentMinLength = ({ text = '帖子内容不能少于5个字' }) => (
    <Tag.div className="toast toast-large-message">

        <Tag.div className="text">

            <Tag.p>{text}</Tag.p>

        </Tag.div>

    </Tag.div>
);

export const ReportSuccess = () => (
    <Tag.div className="toast toast-success">

        <Tag.div className="toast-icon">
            <TcIconTick />
        </Tag.div>

        <Tag.div className="text">举报成功</Tag.div>

    </Tag.div>
);

export const Notice = ({ text = '你有必填项未填写' }) => (
    <Tag.div className="toast toast-notice">

        <Tag.div className="toast-icon">
            <TcIconInfo />
        </Tag.div>

        <Tag.div className="text">{text}</Tag.div>

    </Tag.div>
);

/**
 * 点赞反馈
 * @param {array} likePeople
 * @param {number} likePeopleCount
 */
export const LikeToast = ({ likePeople = [], likePeopleCount = 0 }) => {
    return (
        <Tag.div className="like_toast">
            <Tag.div className="font_content">
                谢谢你的鼓励
            </Tag.div>
            {likePeopleCount > 0 &&
                <Tag.div className="font_content like_boss">
                    {likePeople.slice(0, 3).join(', ')}

                    {likePeopleCount <= 3
                        ? <Tag.p>也觉得赞</Tag.p>
                        : <Tag.p>等{likePeopleCount}人也觉得赞</Tag.p>
                    }

                </Tag.div>
            }
        </Tag.div>

    );

};

/**
 * pc点赞反馈
 * @param {array} likePeople
 * @param {number} likePeopleCount
 */
export const PcLikeToast = ({ likePeople = [], likePeopleCount = 0 }) => {
    return (
        <Tag.div className="pc_like_toast">
            <Tag.div className="font_content">
                谢谢你的鼓励<i className="emjo_icon"></i>
            </Tag.div>
            {likePeopleCount > 0 &&
                <Tag.div className="font_else">
                    {likePeople.slice(0, 3).join(', ')}

                    {likePeopleCount <= 3
                        ? <Tag.span>也觉得赞</Tag.span>
                        : <Tag.span>等{likePeopleCount}人也觉得赞</Tag.span>
                    }

                </Tag.div>
            }
        </Tag.div>

    );

};

/**
 * 移动端展示方式设置反馈
 */
export const MobileShowTypeToast = () => {
    return (
        <Tag.div className="pc_like_toast">
            <Tag.div className="font_content">
                设置成功
            </Tag.div>
        </Tag.div>
    );
};


export const IsLiked = () => {
    return (
        <Tag.div className="like_toast">
            <Tag.div className="font_content">
                已经点过赞啦，请稍后再试
            </Tag.div>
        </Tag.div>

    );

};

